<!-- UserModal.vue -->
<template>
	<b-modal
		v-if="userData"
		v-model="modalVisible"
		no-close-on-esc
		no-close-on-backdrop
		hide-footer
		centered
		size="xl"
		:title="translate('verifying_order_id', { order_id: userData.id })"
		@close="close">
		<div>
			<b-row>
				<b-col cols="8">
					<p>{{ translate('user_id') }} : {{ userData.attributes.user_id }}</p>
					<p>{{ translate('username') }} : {{ userData.attributes.username }}</p>
					<p>{{ translate('id_number') }} : {{ userData.attributes.id_number }}</p>
					<p>{{ translate('recipients_name') }} : {{ userData.attributes.ship_to }}</p>
				</b-col>
				<b-col cols="4">
					<b-button
						variant="success"
						block
						icon-class="fa fa-check"
						class="bg-success-alt"
						@click="approve">
						{{ translate('approve') }}
					</b-button>
					<b-button
						variant="success"
						block
						icon-class="fa fa-check"
						@click="askForUpdate">
						{{ translate('ask_for_update') }}
					</b-button>
					<b-button
						v-if="$can('personal_documents', 'reject')"
						variant="danger"
						block
						icon-class="fa fa-times"
						@click="reject">
						{{ translate('reject') }}
					</b-button>
				</b-col>
			</b-row>
			<is-loading
				v-if="preloadedImages[userData.id].loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="preloadedImages[userData.id].loading" />
			<div
				v-else
				class="d-flex justify-content-center"
				style="gap: 10px">
				<img
					:src="preloadedImages[userData.id].front || require('@/assets/images/common/notFoundAvatar.png')"
					:width="preloadedImages[userData.id].front ? '50%' : '30%'"
					alt="User Image">
				<img
					:src="preloadedImages[userData.id].back || require('@/assets/images/common/notFoundAvatar.png')"
					:width="preloadedImages[userData.id].back ? '50%' : '30%'"
					alt="User Image">
			</div>
			<div
				class="mt-3 d-flex justify-content-end"
				style="gap: 10px">
				<b-button
					:disabled="items.length === 1"
					@click="previous">
					<i class="fa fa-fw fa-chevron-left" />
					{{ translate('previous') }}
				</b-button>
				<b-button
					:disabled="items.length === 1"
					@click="next">
					{{ translate('next') }}
					<i class="fa fa-fw fa-chevron-right" />
				</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import Order from '@/util/Order';
import OrderVerification from '@/mixins/OrderVerification';
import {
	OrderVerifications, Users,
	Grids, Tooltip,
} from '@/translations';

export default {
	name: 'DocumentVerificationModal',
	messages: [OrderVerifications, Users, Grids, Tooltip],
	mixins: [OrderVerification],
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		currentIndex: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			modalVisible: true,
			orderData: new Order(),
			areThereUpdates: false,
			qtyToPreload: 4,
			preloadedImages: {},
		};
	},
	computed: {
		userData() {
			return this.items[this.currentIndex];
		},
	},
	watch: {
		items() {
			this.preloadImages(this.currentIndex);
		},
		currentIndex: {
			handler(index) {
				this.preloadImages(index);
			},
			immediate: true,
		},
	},
	methods: {
		approve() {
			this.modalVisible = false;
			this.approveOrderVerification(this.userData.id, {
				onFinally: (success) => {
					this.modalVisible = true;
					this.afterAction(success);
				},
				onCancel: () => {
					this.modalVisible = true;
				},
			}, true);
		},
		askForUpdate() {
			this.modalVisible = false;
			this.requestDocumentUpdate(this.userData.id, {
				onFinally: (success) => {
					this.modalVisible = true;
					this.afterAction(success);
				},
				onCancel: () => {
					this.modalVisible = true;
				},
			}, true);
		},
		reject() {
			this.modalVisible = false;
			this.rejectOrderVerification(this.userData.id, {
				onFinally: (success) => {
					this.modalVisible = true;
					this.afterAction(success);
				},
				onCancel: () => {
					this.modalVisible = true;
				},
			}, true);
		},
		getImages(orderId) {
			this.$set(this.preloadedImages, orderId, { loading: true });
			return new Promise((resolve) => {
				Promise.allSettled([
					this.fetchDocumentImage(orderId, 'front'),
					this.fetchDocumentImage(orderId, 'back'),
				]).then((data) => {
					const [frontData, backData] = data;
					const front = frontData.status === 'fulfilled' ? frontData.value : null;
					const back = backData.status === 'fulfilled' ? backData.value : null;
					this.$set(this.preloadedImages, orderId, { front, back, loading: false });
					resolve(true);
				}).finally(() => {
					this.preloadedImages[orderId].loading = false;
				});
			});
		},
		fetchDocumentImage(orderId, side) {
			return new Promise((resolve, reject) => {
				this.orderData.getDocument(orderId, side).then(() => {
					const { response } = this.orderData.data;
					const url = window.URL.createObjectURL(response.data);
					resolve(url);
				}).catch((error) => {
					reject(error);
				});
			});
		},
		previous() {
			if (this.currentIndex === 0) {
				this.$emit('update:currentIndex', this.items.length - 1);
				return;
			}
			this.$emit('update:currentIndex', this.currentIndex - 1);
		},
		next() {
			if (this.currentIndex === this.items.length - 1) {
				this.$emit('update:currentIndex', 0);
				return;
			}
			this.$emit('update:currentIndex', this.currentIndex + 1);
		},
		afterAction(success) {
			// If success, delete the item from the list
			if (success) {
				this.areThereUpdates = true;
				const newItems = this.items.filter((item, index) => index !== this.currentIndex);
				this.$emit('update:items', newItems);
				if (this.currentIndex > newItems.length - 1) {
					this.$emit('update:currentIndex', newItems.length - 1);
				}
				// If no items left, close the modal and reload the items
				if (newItems.length === 0) {
					this.$emit('close');
					this.$emit('reload');
				}
			}
		},
		close() {
			this.$emit('close');
			if (this.areThereUpdates) {
				this.$emit('reload');
			}
		},
		preloadImages(index) {
			let i = index;
			let qtyPreloaded = 0;
			// eslint-disable-next-line no-constant-condition
			while (true) {
				if (i >= this.items.length) {
					i = 0; // Reset index
				}
				if (typeof this.preloadedImages[this.items[i].id] === 'undefined') {
					this.getImages(this.items[i].id);
				}
				i += 1;
				qtyPreloaded += 1;
				if (qtyPreloaded === this.qtyToPreload) {
					break;
				}
			}
		},
	},
};
</script>
